<template>
	<v-app id="application">
		<v-main class="fill-height">
			<router-view />
		</v-main>
	</v-app>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {
      heartbeatInterval: null,
      newVersionAvailableVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      currentUser: "getCurrentUser",
    }),
  },
  mounted() {
    function adjustContentHeight() {
      const vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--vh", `${vh}px`);
    }

    adjustContentHeight();
    window.addEventListener("resize", adjustContentHeight);
  },
  beforeDestroy() {
    if (this.heartbeatInterval) {
      clearInterval(this.heartbeatInterval);
    }
  },
};
</script>
