module.exports = {
	title: 'Compatibility Check',
	validators: {
		required: 'This field is required',
		minValue: 'Min value allowed is {value}',
		maxValue: 'Max value allowed is {value}',
		passwordMustMatch: 'Password must be matched',
		invalidEmail: 'Invalid Email',
		maxLength: 'Field should not exceed {length} characters',
		selectAtLeastOne: 'Select at least one option',
		firstName: 'Name is required',
		lastName: 'Last name is required',
		mustSelect: 'You must select {length} criterion',
		optionalPrefix: '(opt.)'
	},
	errorMessages: {
		'Failed to fetch':
				'You have connection issues, please check your internet and try again',
		USER_EMAIL_ALREADY_CREATED:
				'User with this email has been already registered',
		INVALID_PERMISSIONS:
				'The resource you are looking for is not available currently. <br /> It seems the resource has some privacy settings applied.'
	},
	pages: {
		common: {
			oops: 'Oops :(',
			tabs: {
				main: 'Main',
				campaigns: 'Campaigns',
				profileSettings: 'Profile Settings'
			},
			loading: 'Loading',
			logout: 'Logout',
			copied: 'Copied',
			actions: 'Actions'
		},
		signIn: {
			title: 'Sign In',
			form: {
				email: 'Your email',
				password: 'Password'
			},
			forgotYourPassword: 'Forgot your password?',
			signUp: 'Sign Up',
			submit: 'Sign In',
			or: 'or'
		},
		signUp: {
			title: 'Sign Up',
			form: {
				fullName: 'Your name',
				email: 'Your email'
			},
			submit: 'Submit',
			or: 'or',
			signIn: 'Sign In',
			youRegisteredSuccessfully:
					'You were registered successfully, please follow instructions sent by email.'
		},
		passwordRecovery: {
			title: 'Password Recovery',
			form: {
				yourEmail: 'Your email'
			},
			rememberYourPassword: 'Remember your password?',
			submit: 'Submit',
			instructionDescription:
					'Password recovery instruction was send to your email, please check your email',
			goToSignInScreen: 'Go to Sign In screen'
		},
		passwordRecoveryConfirmation: {
			title: 'Password Recovery Confirmation',
			invalidResetTokenDescription:
					'Invalid reset token. Please try to recover your password again',
			goToPasswordRecoveryScreen: 'Go to Password Recovery Screen',
			form: {
				password: 'Enter new password',
				passwordConfirmation: 'Confirm your new password'
			},
			submit: 'Change Password',
			yourPasswordSuccessfullyChanged: 'Your password changed successfully',
			goToSignInScreen: 'Go to Sign In screen'
		},
		profile: {
			main: 'Main'
		},
		profileSettings: {
			title: 'Profile settings',
			mainInfo: 'Main info',
			form: {
				fullName: 'First name',
				email: 'Email'
			},
			profilePhoto: 'Change photo'
		},
		campaigns: {
			title: 'Campaigns',
			add: 'Add Campaign',
			noListFound: 'No campaigns added yet',
			privacy: {
				public: 'Public',
				hidden: 'Hidden'
			},
			details: {
				tabs: {
					edit: 'Edit',
					responses: 'Responses'
				},
				form: {
					title: 'Title',
					privacy: 'Privacy'
				},
				addQuestion: 'Add Question',
				noAnyQuestions: 'No any campaign questions found',
				deleteConfirmation: 'Are you sure to delete campaign?',
				questionDeleteConfirmation: 'Are you sure to delete campaign question?',
				questions: {
					addSubQuestion: 'Add Sub-Question',
					answers: 'Predefined answers',
					questions: 'Questions',
					details: {
						answers: 'Add answers',
						addAnswer: 'Add Answer',
						noAnyAnswers: 'No predefined answers added yet',
						form: {
							title: 'Question',
							waitForResponse: 'Wait for response',
							answerIsRequired: 'Answer is required'
						}
					}
				},
				noAnyResponses: 'No any campaign responses found',
				responses: {
					telNumber: 'Tel. Number',
					email: 'Email',
					points: 'Points'
				}
			}
		},
		campaign: {
			welcomeText: 'Hi! Before applying please check some steps',
			startApplying: 'Start Applying',
			formTitle: 'A bit about you...',
			form: {
				fullName: 'Your name',
				telNumber: 'Your tel. number',
				email: 'Your email'
			}
		},
		logout: {
			loggingOut: 'Logging out...'
		},
		accessDenied: {
			title: 'Access denied, not enough privileges'
		},
		pageNotFound: {
			title: 'Page not found'
		}
	},
	buttons: {
		cancel: 'Cancel',
		ignore: 'Ignore',
		ok: 'OK',
		delete: 'Delete',
		unlink: 'Unlink',
		create: 'Create',
		save: 'Save',
		check: 'Check',
		link: 'Link',
		submit: 'Submit',
		confirm: 'Confirm',
		quit: 'Quit',
		back: 'Back',
		previous: 'Previous',
		next: 'Next',
		continue: 'Continue',
		start: 'Start',
		preview: 'Preview',
		edit: 'Edit',
		view: 'View',
		join: 'Join',
		close: 'Close',
		reload: 'Reload',
		share: 'Share'
	},
	camera: {
		errors: {
			CAMERA_IS_OFF: {
				title: 'Camera is off'
			},
			CAMERA_OR_MIC_PERMISSION_ERROR: {
				title: 'Camera or mic. permission was not granted',
				description:
						'To fix this problem please grant it in your browser and after reload the page'
			},
			CAMERA_UNAVAILABLE_ERROR: {
				title: 'Camera is unavailable',
				description:
						'While requesting camera access browser couldn\'t provide access to camera'
			},
			MIC_PERMISSION_ERROR: {
				title: 'Mic permission was not granted',
				description:
						'To fix this problem please grant it in your browser and after reload the page'
			},
			MIC_UNAVAILABLE_ERROR: {
				title: 'Mic is unavailable',
				description:
						'While requesting mic access browser couldn\'t provide access to mic'
			}
		}
	}
};
