import Vue from "vue";
import Vuetify from "vuetify/lib/framework";
import en from "vuetify/lib/locale/en";
// import "material-design-icons-iconfont/dist/material-design-icons.css";
import "@mdi/font/css/materialdesignicons.css";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#387fe5",
        secondary: "#25A1C6",
        strongSecondary: "#00496F",
        tertiary: "#707070",
      },
    },
  },
  icons: {
    iconfont: "mdi", // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
  lang: {
    locales: { en },
    current: "en",
  },
});
